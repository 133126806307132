<!-- 门店管理--就餐记录 -->

<template>
  <div class="dining-records main-cnt">
    <!-- 就餐记录 -->
    <div class="title">就餐记录</div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="StoreApi.getRecordList" :columns="tableColumns" @projectChange="projectChange">
        <template #operate>
          <el-button type="primary" @click="onExportBtn" :loading="isLoading"
            v-if="authData.indexOf('n_xzo076b7o96fwbvyufkbwyr0nj07') != -1">导出记录</el-button>
        </template>

        <template #user_identity="{ row }">
          <div class="flex align-center">
            <div class="dots" :class="row.user_identity == '1' ? 'bg-blue' : 'bg-yellow'"></div>
            <div>{{ row.user_identity == '1' ? '内部' : '外部' }}</div>
          </div>
        </template>

        <template #dining_type="{ row }">
          <div class="status-text" :class="row.dining_type == '1' ? 'btn-green' : 'btn-orange'">
            <div>{{ row.dining_type == '1' ? '免费' : '付费' }}</div>
          </div>
        </template>
      </common-table>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch } from "vue";
  import { BasicApi, StoreApi, } from "@/plugins/api.js";
  import { useStore } from "vuex";
  import dayjs from "dayjs";
  import getBaseUrl from "@/plugins/baseUrl.js";
  import { ElMessage } from "element-plus";

  const baseUrl = getBaseUrl() ? getBaseUrl() : "";
  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  // 筛选配置
  const filters = ref([
    {
      filterType: "search",
      name: "keywords",
      value: "",
      placeholder: "请输入用户姓名或手机号查询",
    },
    {
      filterType: "select",
      name: "pid",
      value: "",
      placeholder: "请选择项目",
      options: [],
      lab: "p_name",
      val: "p_id",
      action: "projectChange",
    },
    {
      filterType: "select",
      name: "pom_id",
      value: "",
      placeholder: "请选择门店",
      options: [],
      lab: "pom_name",
      val: "pom_id",
    },
    {
      filterType: "select",
      name: "user_identity",
      value: "",
      placeholder: "请选择人员类型",
      options: [{ id: 1, name: '内部' }, { id: 2, name: '外部' },],
      val: "id",
      lab: "name",
    },
    {
      filterType: "select",
      name: "dining_type",
      value: "",
      placeholder: "请选择就餐单类型",
      options: [{ id: 1, name: '免费' }, { id: 2, name: '付费' },],
      val: "id",
      lab: "name",
    },
    {
      filterType: "date",
      name: "stime",
      name2: "etime",
      value: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      placeholder: "请选择下单日期",
      type: "daterange",
    },
  ]);
  // 表格对象
  const tableRef = ref(null);
  // 表格配置数据
  const tableColumns = ref([
    {
      prop: "sn",
      label: "就餐编号",
      minWidth: 120,
    },
    {
      prop: "goods_name",
      label: "商品名称",
    },
    {
      prop: "user_name",
      label: "就餐人",
    },
    {
      prop: "user_mobile",
      label: "手机号",
      minWidth: 100,
    },
    {
      prop: "user_identity",
      label: "人员类型",
      type: "customRender",
    },
    {
      prop: "store_name",
      label: "门店名称",
    },
    {
      prop: "dining_type",
      label: "就餐单类型",
      type: "customRender",
    },
    {
      prop: "goods_price",
      label: "单价",
    },
    {
      prop: "buy_num",
      label: "数量",
    },
    {
      prop: "pay_money",
      label: "支付金额",
    },
    {
      prop: "register_time",
      label: "下单时间",
      minWidth: 130,
    },
    {
      prop: "scan_time",
      label: "扫码时间",
      minWidth: 130,
    },
    {
      prop: "verification_time",
      label: "核销时间",
      minWidth: 130,
    },
  ]);
  const isLoading = ref(false);  // 导出按钮加载状态
  /**
   * 
   * 获取项目选项
   * 
   * */
  const getProjectData = () => {
    BasicApi.projectSelect().then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取项目数据失败！";
        ElMessage.error(msg);
      }
    });
  };

  const projectChange = (obj) => {
    filters.value[2].value = "";
    filters.value[2].options = [];
    tableRef.value.tableLoad();
    if (obj.value) {
      BasicApi.getMerchantByProject({ pid: obj.value }).then((res) => {
        if (res.Code === 200) {
          filters.value[2].options = res.Data ? res.Data : [];
        } else {
          ElMessage.error(res.Message);
        }
      });
    }
  };
  /**
   * 
   * 导出记录按钮
   * 
   * */
  const onExportBtn = () => {
    // 参数
    let reqData = {
      keywords: filters.value[0].value,
      pom_id: filters.value[2].value,
      user_identity: filters.value[3].value,
      dining_type: filters.value[4].value,
      stime: filters.value[5].value ? filters.value[5].value[0] : '',
      etime: filters.value[5].value ? filters.value[5].value[1] : '',
    };
    // 导出数据接口
    StoreApi.getRecordExport(reqData).then((res) => {
      if (res.Code === 200) {
        location.href = `${baseUrl}${res.Data.download_url}`;
        isLoading.value = false;
      } else {
        ElMessage.error(res.Message);
      }
    });
  }

  onMounted(() => {
    getProjectData();
    // 获取分类列表数据
    tableRef.value.tableLoad();
  });
</script>

<style lang="scss">
  .dining-records {
    font-family: "Source Han Sans CN";

    .content {
      padding: 15px 20px 20px;
    }
  }
</style>